import React, { FunctionComponent } from 'react'
import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs'
import { ReactElement } from 'react'
import { translate } from '../../helpers/translate'
import Typography from '@material-ui/core/Typography'
import MuiLink from '@material-ui/core/Link'
import { ExpandRightIcon } from '../../icons/generated/ExpandRightIcon'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'gatsby'

type Props = {
  crumbs: { title: string; route: string }[] // Array of every crumb for the component, with title and route, usually starting with the home
  currentPageTitle: string
}

const useStyles = makeStyles(theme => ({
  breadcrumbs: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  separator: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))

/**
 * Breadcrumbs component. No smart integration with router as it's not 1-to-1
 */
export const Breadcrumbs: FunctionComponent<Props> = ({
  crumbs,
  currentPageTitle,
}): ReactElement => {
  const classes = useStyles()
  return (
    <MuiBreadcrumbs
      className={classes.breadcrumbs}
      separator={
        <div className={classes.separator}>
          <ExpandRightIcon fontSize="small" />
        </div>
      }
      aria-label={translate('breadcrumbsLabel')}
    >
      {crumbs.map(crumb => (
        <MuiLink key={crumb.route} component={Link} to={crumb.route}>
          {crumb.title}
        </MuiLink>
      ))}
      <Typography color="textPrimary">{currentPageTitle}</Typography>
    </MuiBreadcrumbs>
  )
}
