import Button from '@material-ui/core/Button'
import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import React, { ReactElement } from 'react'
import { Link } from 'gatsby'
import { translate } from '../helpers/translate'
import { useBasket } from '../modules/basket'
import { routes } from '../components/Router/routes'
import { Breadcrumbs } from '../components/Breadcrumbs/Breadcrumbs'
import { BasketPriceTable } from '../components/BasketPriceTable'
import { BasketProductList } from '../components/Basket/BasketProductList'
import { ErrorMessage } from '../components/ErrorMessage'
import Layout from '../Layout/Layout'
import Seo from '../components/Seo'
import { usePageViewAnalytics } from '../hooks/useAnalytics'

const useStyles = makeStyles(({ spacing, breakpoints }) => {
  return {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      [breakpoints.down('sm')]: {
        flexDirection: 'column',
        '& > :first-child': {
          marginBottom: spacing(4),
        },
      },
    },
    basketTotals: {
      marginTop: spacing(4.75),
      marginBottom: spacing(3),
      display: 'flex',
      justifyContent: 'space-between',
      [breakpoints.down('sm')]: {
        display: 'block',
        marginTop: spacing(3),
        '& > *:first-child': {
          marginBottom: spacing(3),
        },
      },
    },
    basketPriceTableWrapper: {
      width: spacing(60),
      [breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    actionButton: {
      [breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }
})

const BasketPage = (): ReactElement => {
  const basket = useBasket()
  const classes = useStyles()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const { lines } = basket
  const items = lines?.map(product => {
    return {
      brand: product.brand,
      name: product.name,
      id: product.variantId,
      quantity: `${product.amount}`,
      price: `${product.unitPrice}`,
    }
  })

  // Adobe Analytics - Global Variables
  usePageViewAnalytics({
    trackingPageInfo: {
      pageName: 'kurv - shop',
      siteSection: 'shop',
      subSection1: 'kurv',
      pageType: 'kurv',
      id: 'kurv-shop',
      pageVariant: 'SMB',
    },
    trackingProductsInfo: {
      customEvent: 'view_basket',
      items,
    },
  })

  if (basket.initializing) {
    return <CircularProgress size={'1.6em'} />
  }

  return (
    <Layout>
      <Seo lang="da" title={`Kurv`} description="TDC Erhverv shop" />
      <Breadcrumbs
        crumbs={[routes.home]}
        currentPageTitle={routes.basket.title}
      />
      <div className={classes.header}>
        <Typography variant="h4" component="h1">
          {translate('basket.basketPageTitle')}
        </Typography>
        <Button
          className={classes.actionButton}
          size={isSmallScreen ? undefined : 'small'}
          component={Link}
          to={routes.home.route}
          variant="outlined"
        >
          {translate('basket.basketPageContinueBrowsing')}
        </Button>
      </div>
      <BasketProductList lines={basket.lines} />
      {basket.isAboveMaximumTotal && (
        <Box mt={1} mb={1.5}>
          <ErrorMessage message={translate('basket.errors.orderAboveLimit')} />
        </Box>
      )}
      <div className={classes.basketTotals}>
        <Typography component="h6">
          {translate('basket.itemsInBasketAmount', {
            amount: String(basket.amount || 0),
          })}
        </Typography>
        <div className={classes.basketPriceTableWrapper}>
          <BasketPriceTable />
        </div>
      </div>
      <Box mb={11.5}>
        {(basket.amount || 0) > 0 && (
          <Typography align="right">
            <Button
              disabled={basket.isAboveMaximumTotal}
              className={classes.actionButton}
              component={Link}
              to={routes.checkout.route}
              variant="contained"
              color="primary"
            >
              {translate('basket.proceedToCheckout')}
            </Button>
          </Typography>
        )}
      </Box>
    </Layout>
  )
}
export default BasketPage
