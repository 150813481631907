/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { ISeo, IQueryTypes } from './types'
import { useLocation } from '@reach/router'

const Seo = ({
  description,
  lang,
  meta,
  title,
  robot,
  featuredImage,
  structuredData,
}: ISeo) => {
  const { site, file } = useStaticQuery<IQueryTypes>(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `,
  )

  // Use the location hook to get current page URL
  const location = useLocation()

  const ogImage = file?.childImageSharp?.gatsbyImageData

  const metaDescription = description || site.siteMetadata.description
  const metaRobot = robot || 'all'
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s - ${defaultTitle}` : null}
      link={[
        {
          rel: 'canonical',
          href: `${site?.siteMetadata?.siteUrl}${location.pathname}`,
        },
      ]}
      meta={[
        {
          name: 'robots',
          content: metaRobot,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: `${site?.siteMetadata?.siteUrl}${ogImage?.images.fallback.src}`,
        },
        {
          property: 'og:image:width',
          content: `${ogImage?.width}`,
        },
        {
          property: 'og:image:height',
          content: `${ogImage?.height}`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: 'og:site_name',
          content: title,
        },
        {
          property: 'og:url',
          content: `${site?.siteMetadata?.siteUrl}${location.pathname}`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {structuredData?.organization && (
        <script type="application/ld+json">
          {JSON.stringify(structuredData.organization)}
        </script>
      )}
      {structuredData?.article && (
        <script type="application/ld+json">
          {JSON.stringify(structuredData.article)}
        </script>
      )}
      {structuredData?.product && (
        <script type="application/ld+json">
          {JSON.stringify(structuredData.product)}
        </script>
      )}
      {structuredData?.breadcrumb && (
        <script type="application/ld+json">
          {JSON.stringify(structuredData.breadcrumb)}
        </script>
      )}
      {structuredData?.video && (
        <script type="application/ld+json">
          {JSON.stringify(structuredData.video)}
        </script>
      )}
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `da`,
  meta: [],
  description: ``,
  robot: `all`,
}

export default Seo
